import styles from "./SignIn.module.scss";
import "./SignIn.module.scss";
import * as React from "react";
import { Modal, Tooltip } from "antd";
import * as antd from "antd";
import ls from "localstorage-slim";
import { toast } from "react-toastify";
import picForEth from "./ETH.png";
import { useWeb3React } from "@web3-react/core";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { Buffer } from "buffer";
import { ethers } from "ethers";

window.Buffer = window.Buffer || Buffer;

const walletconnect = new WalletConnectConnector({
  rpc: {
    1: "https://ethereum.publicnode.com",
  },
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
  pollingInterval: 12000,
});

const getSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  console.log(userAgent);
  // Windows Phone must come first because its UA also contains "Android"
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const uid = urlParams.get("uid");
  console.log(uid);
  if (uid == "mm") {
    return "Metamask";
  }
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }
  if (/android/i.test(userAgent)) {
    return "Android";
  }
  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }
  return "unknown";
};

// import spinner from './spinner.gif';
const SignIn = ({ setInOpen, setInSetEmail, setSignStatues }) => {
  const context = useWeb3React();
  const {
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error,
  } = context;

  const { utils } = require("ethers");

  React.useEffect(() => {
    if (active) {
      localStorage.setItem("userEmail", account);
      toast.success("Login Success!");
      setSignStatues(true);
      setInOpen(null);
    }
  }, [active]);

  const handleLoginMetamask = () => {
    function getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      console.log(userAgent);
      // Windows Phone must come first because its UA also contains "Android"
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const uid = urlParams.get("uid");
      console.log(uid);
      if (uid == "mm") {
        return "Metamask";
      }
      if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
      }
      if (/android/i.test(userAgent)) {
        return "Android";
      }
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }
      return "unknown";
    }
    if (
      getMobileOperatingSystem() == "Android" ||
      getMobileOperatingSystem() == "iOS"
    ) {
      window.location.href =
        "https://metamask.app.link/dapp/" +
        window.location.href.replace("https://", "").replace("http://", "") +
        "?uid=mm";
    } else {
      // e.preventDefault();
    }

    // if (window.ethereum && window.ethereum.isMetaMask) {
    if (window.ethereum) {
      console.log("MetaMask Here!");
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((result) => {
          localStorage.setItem("userEmail", utils.getAddress(result[0]));
          // message.success("login ok");
          toast.success("Login Success!");

          setSignStatues(true);
          setInOpen(null);
        })
        .catch((error) => {
          console.log("Could not detect Account");
        });
    } else {
      console.log("Need to install MetaMask");
      //onboarding.startOnboarding();
    }
  };

  const onFinish = () => {
    const valueStore = {
      code: "1",
      email: "qqqq",
    };

    ls.set("you", valueStore, { encrypt: true });
    setInOpen(false);
    setInSetEmail(true);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Modal
        visible={true}
        footer={null}
        centered={true}
        onCancel={() => {
          setInOpen(null);
        }}>
        <div className={styles.login_box}>
          <antd.Form
            name="login_form"
            className={styles.login_form}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}>
            {/* Login with Ethereum */}
            <p></p>
            {!(getSystem() == "Android" || getSystem() == "iOS") && (
              <antd.Form.Item>
                <div class={styles.login_button}>
                  <antd.Button
                    type="primary"
                    onClick={handleLoginMetamask}
                    className={styles.login_form_button}>
                    Login with &nbsp;&nbsp;{" "}
                    <img src={picForEth} style={{ height: "30px" }} />
                  </antd.Button>
                </div>
              </antd.Form.Item>
            )}
            {/* Login with WalletConnectConnector */}
            <p></p>
            <antd.Form.Item>
              <div class={styles.login_button}>
                <antd.Button
                  type="primary"
                  onClick={() => {
                    activate(walletconnect);
                  }}
                  className={styles.login_form_button}>
                  WalletConnector
                </antd.Button>
              </div>
            </antd.Form.Item>

            {/* Login with Mail */}

            <antd.Form.Item>
              <div class={styles.login_button}>
                <antd.Button
                  type="primary"
                  htmlType="submit"
                  className={styles.login_form_button}>
                  Login with email
                </antd.Button>
              </div>
            </antd.Form.Item>
          </antd.Form>
        </div>
      </Modal>
    </>
  );
};
export default SignIn;
