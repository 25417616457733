import { useContext, useState } from 'react';
import styles from './ProductModal1.module.scss';

import { Link } from 'react-router-dom'
import {
    Modal,
    Row,
    Col,
    Radio,
    message
} from 'antd';
import { GlobalStates } from '../../Contexts/GlobalStates';

const ProductModal2 = ({ product, setViewDesignAgree, setDesignAgree }) => {
    const { setGRefresh } = useContext(GlobalStates);
    const [agree, setAgree] = useState(0)
    const View = ()=>{
    	setDesignAgree(agree); 
	setViewDesignAgree(null);
    }
    const Change = ()=>{
    	if(agree){
		setAgree(0)
	}else{
		setAgree(1)
	}
    }
    return (
        <Modal
            visible={product ? true : false}
            footer={null}
            width={window.innerWidth >= 576 ? '60vw' : '95vw'}
            centered={true}
            onCancel={() => setViewDesignAgree(null)}
        ><br></br>
	<div class="modal-content-wrapper">
		<div class="modal-header"><div class="pf-d-flex pf-flex-wrap pf-justify-content-between pf-align-items-center">
			<div class="order-1 basis-md-auto basis-80">
				<h2 id="modal-4-label" class="pf-h3 pf-m-0 pf-p-0 pf-border-0">Please double check your embroidery file</h2>
			</div>
			<div class="order-3 text-right basis-md-auto basis-20">
				<span tabindex="0" aria-label="Close" role="button" class="pf-i pf-i-32 pf-i-close pf-modal__close-icon pf-keydown-click"></span>
			</div>
		</div></div>
		
		<div class="alert alert-warning">
			<p class="pf-mb-0"><strong>Please format your files according to the information below and on</strong> <a href="https://client-8qhfb.ondigitalocean.app/creating-embroidery-file" class="text-bold" target="_blank"  style={{color: 'blue'}}> our guidelines </a> to make sure we can have them embroidered as quickly as possible.</p><p class="pf-mb-18"><strong>If your graphic doesn't meet our requirements, we can't guarantee the quality of the end product.</strong></p>
			<div class="pf-mb-18">
				<p class="pf-mb-0"><strong>Please note:</strong></p><p class="pf-mb-0">1. Highly <strong>detailed graphics </strong>that ignore our guidelines <strong>may be run stitched </strong> (see #8 below)</p><p class="pf-mb-0">2. Orders made with embroidery files that are otherwise defective <strong>will be put on hold</strong></p>
			</div>
			<p class="pf-mb-0">To create more accurate designs and visualize the outcome of your product, order our <a href="https://client-8qhfb.ondigitalocean.app/embroidery-sample" class="text-bold" target="_blank" style={{color: 'blue'}}>embroidery sample</a>.</p>
		</div>
		
		<div class="pf-mb-0">
			<img src="/warning.png" style={{ width: '100%', height: window.innerWidth >= 650 ? '50%' : '30%' }} className={styles.the_image} />
		</div>

		<div>
			<ol class="emb-list emb-list-modal"><li><div class="emb-title color_dark-red"><span class="number"></span>High profile template</div></li><li><div class="emb-title color_soft-green"><span class="number"></span>Low profile template</div></li><li><div class="emb-title color_medium-blue"><span class="number"></span>Side logo template</div></li><li><div class="emb-title color_soft-blue"><span class="number"></span>Flat embroidery</div></li><li><div class="emb-title color_black"><span class="number"></span>3D Puff embroidery</div><div class="emb-desc">A technique where embroidery is raised. Used mostly for big shapes.</div></li><li><div class="emb-title color_soft-yellow"><span class="number"></span>Partial 3D Puff</div><div class="emb-desc">A technique where both Flat embroidery and 3D Puff used in the same graphic.</div></li><li><div class="emb-title color_soft-brown"><span class="number"></span>Satin stitch outline</div><div class="emb-desc">Used to outline an object or to cap a 3D Puff design. It's a long, straight embroidery stitch that looks like satin.</div></li><li><div class="emb-title color_soft-grey"><span class="number"></span>Run stitch</div><div class="emb-desc">The running stitch is the basic stitch embroidery. The stitch is worked by passing the needle in and out of the fabric going straight forward in a line.</div></li><li><div class="emb-title color_soft-pink"><span class="number"></span>Tatami fill</div><div class="emb-desc">Used to fill large areas.</div></li></ol>
		
			<label class="pf-p-16 popup-label"><input type="checkbox" onChange={() => {Change()}}/> I have reviewed the requirements, don't show this again.</label>
		</div>
		<br></br>
		<div class="pf-p-16 border-top">
		<Radio.Button onClick={() => {View()}} style={{color: 'grey'}}>I accept</Radio.Button>
		</div>
	</div>
        </Modal>
    );
};

export default ProductModal2;