import Layout from '../../Components/Layout/Layout';
import styles from './LearnMore.module.scss';
import { Row, Col } from 'antd';

const LearnMore = () => {
    return (
        <Layout
            title={'Learn More - Unme'}
        >
            <section className={'container ' + styles.learn_more}>
                <h1>What is Unme?</h1>

                <p>
                    Unme (you and me) is
                </p>

                <p>
                    (A) a simple option for you to earn crypto (eg BTC, ETH).
                </p>

                <p>
                    (B) A mechanism where the exact amount you earn is also used to help mitigate/solve social problems, by programmatically and on-chain, funding public and social goods, like for example the SDGs (Sustainable Development Goals).
                </p>

                <p>
                    (C) A social and help-the- people-enterprise, born from purpose, determination, and gratitude.
                </p>

                <p>
                    (D) A creator-economy NFT-lab and merch-factory.
                </p>

                <p>
                    {"Integrating blockchain technology into the payment system of the print-on-demand business model, to create a paradigm shift in society >> to support public-and-social goods, while simultaneously sharing part of the proceeds from every sale with the communities (the people in general), the content-creators/designers that mint or upload their work/art/picture/content to the Unme web-protocol."}
                </p>


                <p>
                    We have programmed the money, thus every time a “Product_with_Purpose” or a “NFT with intent” is sold, our protocol can empower as much people as possible by automatically giving 30% of the proceeds to the creator/designer of such sold “item.
                </p>

                <p>
                    From every sale, another 30% of the proceeds continuously support public goods and Open Source projects.
                </p>

                <p>
                    We are also exploring the possibility to programmatically-donate from the proceeds generated by “Products_with_Purpose” or “NFTs with intent” to organizations that work with and help:
                </p>


                <ul>
                    <li>Single mothers</li>
                    <li>Students with debt problems</li>
                    <li>The initiative for UBI (Universal Basic Income) wherever it is being implemented</li>
                </ul>

                <p>We strongly believe that together and with these new business models, the people in general, can and will make a difference.</p>

                <p>Happy creators-economy days! And Power to the People!</p>

                <Row gutter={[26, 26]}>
                    <Col sm={12}>
                        <img src="/lm-1.png" alt="" className={styles.image} />
                    </Col>
                    <Col sm={12}>
                        <img src="/lm-2.png" alt="" className={styles.image} />
                    </Col>
                </Row>
            </section>
        </Layout>
    );
};

export default LearnMore;