import Layout from '../../Components/Layout/Layout';
import styles from './Creatingemb.module.scss';
import { useEffect } from 'react';

const Creatingemb = () => {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 500);
    }, []);
    return (
        <Layout
            title={'How it works - Unme'}
        >
            <section className={styles.how + " " + "container"}>


                <h2>Embroidery 101: Learn the basics</h2>
                <p><img src="https://files.cdn.printful.com/o/upload/lpg-image-upload/f6/f6cc800e7a5e17610e9788dafb8e9c1d" alt="3Dembroidery sample" width="90%"/></p>
                <h2>1. Flat embroidery</h2>
                <p>A technique where stitches are embroidered flat on the surface.</p>
                <h2>2. Thread colors</h2>
                <p>All the available colors.</p>
                <h2>3. Tatami fill</h2>
                <p>Rows of run stitches. Used to fill large areas.</p>
		<h2>4. 3D Puff embroidery</h2>
                <p>A technique where the embroidery is raised. Used mostly for big shapes.</p>
		<h2>5. Satin stitch outline</h2>
                <p>A long, straight embroidery stitch. Used to outline an object or to cap a 3D Puff design.</p>
		<h2>6. Run stitch</h2>
                <p>A single line stitch. Used for small design details.</p>

            </section>
        </Layout>
    );
};

export default Creatingemb;