import { useState, useEffect } from "react";
import styles from "./SignIn.module.scss";
import "./SignIn.module.scss";
import { message } from "antd";
import * as React from "react";
import SignMessage from "./SignMessage";
import axios from "../../config/axios";
import emailjs from "@emailjs/browser";
import ls from "localstorage-slim";
//import MetaMaskProvider from '../hooks/metamask';
import { Modal, Tooltip } from "antd";
import * as antd from "antd";
import { toast } from "react-toastify";

const VerifyCode = ({ setInVerifyCode, setSignStatues, setInSetEmail }) => {
  const [loadings, setLoadings] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoadings(false);
      const valueStore = {
        code: "1",
        email: ls.get("you", { decrypt: true }).email,
      };
      ls.set("you", valueStore, { encrypt: true });
    }, 60000);
  }, []);

  // when click resend the code...
  const enterLoading = () => {
    setLoadings(true);

    setTimeout(() => {
      setLoadings(false);
      const valueStore = {
        code: "1",
        email: ls.get("you", { decrypt: true }).email,
      };
      ls.set("you", valueStore, { encrypt: true });
    }, 60000);

    const codeNumber = Math.floor(900000 * Math.random()) + 100000;

    const valueStore = {
      code: codeNumber,
      email: ls.get("you", { decrypt: true }).email,
    };

    ls.set("you", valueStore, { encrypt: true });

    // console.log(ls.get("you", { decrypt: true }).code);

    // console.log(templateParams);

    // process.env.SERVICE_ID,
    //   process.env.TEMPLATE_ID,
    //   templateParams,
    //   process.env.PUBLIC_KEY,

    const valueSend = {
      code: `<div><div style="font-family:inherit;padding-right:24px;border-left-width:1.5px;border-bottom-color:rgb(30,41,59);padding-left:24px;border-bottom-right-radius:8px;border-bottom-width:1.5px;margin-left:auto;width:202px;padding-bottom:12px;border-top-color:rgb(30,41,59);border-left-style:solid;border-top-width:1.5px;border-bottom-style:solid;border-right-color:rgb(30,41,59);border-right-width:1.5px;border-bottom-left-radius:8px;text-align:center;border-top-style:solid;padding-top:12px;border-top-right-radius:8px;margin-right:auto;border-left-color:rgb(30,41,59);border-top-left-radius:8px;border-right-style:solid"><span style="font-size:20px;letter-spacing:2px">${codeNumber}</span></div><div></div></div>`,
      email: ls.get("you", { decrypt: true }).email,
    };

    emailjs
      .send(
        "service_79plt5t",
        "template_4lzzhi6",
        valueSend,
        "WK_Mfm54S9CohJsba"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
        },
        (err) => {
          console.log("FAILED...", err);
        }
      );
  };

  const onFinish = (values) => {
    // console.log("Verify:", values);
    // console.log("compared to", ls.get("you", { decrypt: true }).code);
    if (values.password == ls.get("you", { decrypt: true }).code) {
      localStorage.setItem("userEmail", ls.get("you", { decrypt: true }).email);
      // console.log("user email", ls.get("you", { decrypt: true }).email);

      // message.success("login ok");
      toast.success("login success");
      if (ls.get("you", { decrypt: true }).superuser) {
        localStorage.setItem("superuser", values.username);
        window.location.href = "/admin";
      }
      setSignStatues(true);
      setInVerifyCode(false);
    } else {
      // setSignStatues(false);
      toast.error("Please input valid code!");
    }
  };

  const onFinishFailed = (errorInfo) => {
    toast.error("Please input valid code!");
    console.log("Failed:", errorInfo);
  };

  const handleBack = () => {
    setInVerifyCode(false);
    setInSetEmail(true);
  };

  return (
    <Modal
      visible={true}
      footer={null}
      centered={true}
      onCancel={() => {
        setInVerifyCode(null);
      }}>
      <div className={styles.login_box}>
        <antd.Form
          name="login_form"
          className={styles.login_form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}>
          <p></p>
          <antd.Form.Item>
            <antd.Button
              type="primary"
              // htmlType="submit"
              className={styles.login_form_button}
              danger
              disabled={loadings}
              loading={loadings}
              onClick={() => enterLoading()}>
              <b>Send code again</b>
            </antd.Button>
          </antd.Form.Item>
          <p></p>

          <div>
            <p1>A code has been sent to:</p1>
          </div>
          <antd.Form.Item name="email">
            <antd.Input
              className={styles.muteText}
              defaultValue={ls.get("you", { decrypt: true }).email}
              disabled="true"
            />
          </antd.Form.Item>

          <div>
            <p1>Verification code</p1>
          </div>
          <Tooltip
            title="A code received will expire after 1 minute and you have to send code again."
            color="green"
            mouseLeaveDelay={0.1}
            className={styles.login_form_button}>
            <antd.Form.Item
              name="password"
              rules={[{ required: true, message: "Please input your code!" }]}>
              <antd.Input
                placeholder="type your code"
                className={styles.code}
              />
            </antd.Form.Item>
          </Tooltip>
          <p></p>
          <antd.Form.Item>
            {/* <div class={styles.login_button}> */}

            <antd.Button
              type="primary"
              onClick={handleBack}
              className={styles.login_form_button_half_r}>
              ◀ Back
            </antd.Button>

            <antd.Button
              type="primary"
              htmlType="submit"
              className={styles.login_form_button_half}>
              Confirm
            </antd.Button>
            {/* </div> */}
          </antd.Form.Item>
        </antd.Form>
      </div>
    </Modal>
  );
};
export default VerifyCode;
