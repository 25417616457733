import { useState } from "react";
import styles from "./SignIn.module.scss";
import "./SignIn.module.scss";
import { message } from "antd";
import * as React from "react";
import SignMessage from "./SignMessage";
import axios from "../../config/axios";
import emailjs from "@emailjs/browser";
import ls from "localstorage-slim";
import { Modal } from "antd";
import * as antd from "antd";
import { toast } from "react-toastify";

const SetEmail = ({ setInVerifyCode, setInSetEmail }) => {
  const [loadings, setLoadings] = useState(false);

  // when click resend the code...
  const enterLoading = () => {
    const email = document.getElementsByName("userEmail")[0].value;

    if (ls.get("you", { decrypt: true }).email == email) {
      setInSetEmail(false);
      setInVerifyCode(true);
    } else {
      const regexExp =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
      const isValidEmail = regexExp.test(email);

      if (isValidEmail) {
        setLoadings(true);

        setTimeout(() => {
          setLoadings(false);
          setInSetEmail(false);
          setInVerifyCode(true);
        }, 2000);

        const codeNumber = Math.floor(900000 * Math.random()) + 100000;

        const valueStore = {
          code: codeNumber,
          email: email,
        };

        ls.set("you", valueStore, { encrypt: true });

        // console.log(ls.get("you", { decrypt: true }).code);

          const valueSend = {
            code: `<div><div style="font-family:inherit;padding-right:24px;border-left-width:1.5px;border-bottom-color:rgb(30,41,59);padding-left:24px;border-bottom-right-radius:8px;border-bottom-width:1.5px;margin-left:auto;width:202px;padding-bottom:12px;border-top-color:rgb(30,41,59);border-left-style:solid;border-top-width:1.5px;border-bottom-style:solid;border-right-color:rgb(30,41,59);border-right-width:1.5px;border-bottom-left-radius:8px;text-align:center;border-top-style:solid;padding-top:12px;border-top-right-radius:8px;margin-right:auto;border-left-color:rgb(30,41,59);border-top-left-radius:8px;border-right-style:solid"><span style="font-size:20px;letter-spacing:2px">${codeNumber}</span></div><div></div></div>`,
            email: email,
          };

        emailjs
          .send(
            "service_79plt5t",
            "template_4lzzhi6",
            valueSend,
            "WK_Mfm54S9CohJsba"
          )
          .then(
            (response) => {
              console.log("SUCCESS!", response.status, response.text);
            },
            (err) => {
              console.log("FAILED...", err);
            }
          );
      } else {
        toast.error("Please input email");
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Modal
      visible={true}
      footer={null}
      centered={true}
      onCancel={() => {
        setInSetEmail(false);
      }}>
      <div className={styles.login_box}>
        <antd.Form
          name="login_form"
          className={styles.login_form}
          initialValues={{ remember: true }}
          onFinishFailed={onFinishFailed}>
          <p></p>

          <h2>Email Address</h2>
          <antd.Form.Item
            name="userEmail"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please input your Email!",
              },
            ]}>
            <antd.Input
              placeholder="Your Email"
              name="userEmail"
              defaultValue={
                ls.get("you", { decrypt: true }).email == "qqqq"
                  ? ""
                  : ls.get("you", { decrypt: true }).email
              }
            />
          </antd.Form.Item>
          <p></p>
          <antd.Form.Item>
            <div class={styles.login_button}>
              <antd.Button
                type="primary"
                className={styles.login_form_button}
                loading={loadings}
                onClick={enterLoading}>
                Next ▶
              </antd.Button>
            </div>
          </antd.Form.Item>

          <p></p>
        </antd.Form>
      </div>
    </Modal>
  );
};
export default SetEmail;
