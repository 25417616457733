import styles from './Category.module.scss';
import { useState, useEffect } from "react";
import Layout from "../../Components/Layout/Layout";
import axios from '../../config/axios';
import { Row, Col,  } from 'antd';
import ProductModal from '../../Components/ProductModal/ProductModal';
import { Link } from 'react-router-dom';
import Loader from '../../Components/Loader/Loader';


const Category = (props) => {

    const [products, setProducts] = useState([]);
    const [productPrice, setProductPrice] = useState([]);
    const [categoriesArray, setCategoriesArray] = useState([]);
    const [catMain, setCatMain] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedSubCat, setSelectedSubCat] = useState('all');
    

    useEffect(() => {
        // wo6ie2bc-7rr5-athx:bc7m-jypr1kw48mw3
	
        axios.get('/public/products').then(response => {
            let prods = response.data;
            setProducts(prods.reverse());
            setLoading(false);
        });
	/*
	axios.get('/printful/products').then(response => {
            let prods = response.data.result;
	    setProducts(prods.reverse());
        });*/

	axios.get('/printful/categories').then(response => {
            let prods = response.data.result.categories;
            setCategoriesArray(prods);
            setLoading(false);
	    setTimeout(() => {
		if(props.match.params.category === 'men'){
			setCatMain(1);
			if(window.innerWidth <= 576){
				document.querySelector('body').scrollTo(0, 650);
			}
		}
		if(props.match.params.category === 'women'){
			setCatMain(2);
			if(window.innerWidth <= 576){
				document.querySelector('body').scrollTo(0, 700);
			}
		}
		if(props.match.params.category === 'hats'){
			setCatMain(93);
			if(window.innerWidth <= 576){
				document.querySelector('body').scrollTo(0, 500);
			}
		}
		if(props.match.params.category === 'kids-&-youth'){
			setCatMain(3);
			if(window.innerWidth <= 576){
				document.querySelector('body').scrollTo(0, 550);
			}
		}
		if(props.match.params.category === 'home-&-living'){
			setCatMain(5);
			if(window.innerWidth <= 576){
				document.querySelector('body').scrollTo(0, 850);
			}
		}
		if(props.match.params.category === 'accessories'){
			setCatMain(4);
			if(window.innerWidth <= 576){
				console.log(props.match.params.category)
				document.querySelector('body').scrollTo(0, 900);
			}
		}	
	    }, 1000);
        });
	
    }, []);

    useEffect(() => {
        
	if(products){
		if(props.match.params.category === 'men'){
			setCatMain(1);
			if(window.innerWidth <= 576){
				document.querySelector('body').scrollTo(0, 650);
			}
		}
		if(props.match.params.category === 'women'){
			setCatMain(2);
			if(window.innerWidth <= 576){
				document.querySelector('body').scrollTo(0, 700);
			}
		}
		if(props.match.params.category === 'hats'){
			setCatMain(93);
			if(window.innerWidth <= 576){
				document.querySelector('body').scrollTo(0, 500);
			}
		}
		if(props.match.params.category === 'kids-&-youth'){
			setCatMain(3);
			if(window.innerWidth <= 576){
				document.querySelector('body').scrollTo(0, 550);
			}
		}
		if(props.match.params.category === 'home-&-living'){
			setCatMain(5);
			if(window.innerWidth <= 576){
				document.querySelector('body').scrollTo(0, 850);
			}
		}
		if(props.match.params.category === 'accessories'){
			setCatMain(4);
			if(window.innerWidth <= 576){
				document.querySelector('body').scrollTo(0, 900);
			}
		}
	}
	setSelectedSubCat('all')
    }, [props]);
     useEffect(async () => {
	/*await Promise.all(
		await products.map(async (product,index)=>{
			if(index>=300 && index<355){
				let temp = await axios.get(`/printful/products/${product.id}`);
				console.log(product.id,",",product.title,",",product.image,",",product.id,",",temp.data.result.variants[0].price,",",product.main_category_id,",",temp.data.result.variants[0].color,",",temp.data.result.variants[0].size,",",temp.data.result.variants[0].id);
			}
		})
		
	)*/
       

        if(window.innerWidth <= 576){
		
		if(props.match.params.category === 'men'){
				document.querySelector('body').scrollTo(0, 650);
		}
		if(props.match.params.category === 'women'){
				document.querySelector('body').scrollTo(0, 700);
		}
		if(props.match.params.category === 'hats'){
				document.querySelector('body').scrollTo(0, 500);
		}
		if(props.match.params.category === 'kids-&-youth'){
				document.querySelector('body').scrollTo(0, 550);
		}
		if(props.match.params.category === 'home-&-living'){
				document.querySelector('body').scrollTo(0, 850);
		}
		if(props.match.params.category === 'accessories'){
				document.querySelector('body').scrollTo(0, 900);
		}
		
	}
    }, [selectedSubCat]);
    function getPrice(id) {
        for (var i = 0; i < productPrice.length; i++) {
            if(productPrice[i].product_id == id){
	    	var t = productPrice[i].price*1.6;
		return t.toFixed(2);
	    }
        }

        return 0;
    }
    function makeTitle(slug) {
        var words = slug.split('-');

        for (var i = 0; i < words.length; i++) {
            var word = words[i];
            words[i] = word.charAt(0).toUpperCase() + word.slice(1);
        }

        return words.join(' ');
    }
    function testcat(cat) {
	var i, temp;
        for(i = 0; i<categoriesArray.length; i++){
		if(cat === categoriesArray[i].id){
			if(categoriesArray[i].parent_id === catMain){
				return true;
			}else{
				temp = categoriesArray[i].parent_id
			}
		}
	}
	for(i = 0; i<categoriesArray.length; i++){
		if(temp === categoriesArray[i].id){
			if(categoriesArray[i].parent_id === catMain){
				return true;
			}
		}
	}
	return false;
    }
    
    function testcat1(cat) {
	var i, temp,temp1;
	
        for(i = 0; i<categoriesArray.length; i++){
		if(cat === categoriesArray[i].id){
			if(categoriesArray[i].parent_id === catMain){
				if(categoriesArray[i].title.toLowerCase().includes(selectedSubCat.toLowerCase())){
					return true;
				}else{
					return false;
				}
			}else{
				temp = categoriesArray[i].parent_id
				temp1 = categoriesArray[i].title
			}
		}
	}
	for(i = 0; i<categoriesArray.length; i++){
		if(temp === categoriesArray[i].id){
			if(categoriesArray[i].parent_id === catMain){
				if(categoriesArray[i].title.toLowerCase().includes(selectedSubCat.toLowerCase()) || temp1.toLowerCase().includes(selectedSubCat.toLowerCase())){
					return true;
				}else{
					return false;
				}
			}
		}
	}
	return false;
    }
    return (
        <Layout
            title={`${makeTitle(props.match.params.category)} - Unme`} 
        >

            {
                loading ?
                    <Loader height={'50vh'} />
                    :
                    <>
                        <section className={'container ' + styles.jump} id="explore" style={{ scrollMarginTop: '8rem' }}>

                            <div >
                                <Link to={'/category/women'}>
                                    <span>Women</span>
                                </Link>
                            </div>
                            <div >
                                <Link to={'/category/men'}>
                                    <span>Men</span>
                                </Link>
                            </div>
                            <div >
                                <Link to={'/category/kids-&-youth'}>
                                    <span>Kids & Youth</span>
                                </Link>
                            </div>
                            <div >
                                <Link to={'/category/hats'}>
                                    <span>Hats</span>
                                </Link>
                            </div>
                            <div >
                                <Link to={'/category/accessories'}>
                                    <span>Accessories</span>
                                </Link>
                            </div>
                            <div >
                                <Link to={'/category/home-&-living'}>
                                    <span>Home & Living</span>
                                </Link>
                            </div>
                        </section>

                        <section className={'container ' + styles.ccats}>
                            <Row gutter={[26, 26]}>
                                <Col sm={9}>
                                    <h1 onClick={() => setSelectedSubCat('all')} style={{ cursor: 'pointer' }}>{makeTitle(props.match.params.category)}</h1>
                                    {/* <Radio.Group onChange={(e) => setSelectedSubCat(e.target.value)} value={selectedSubCat}>
                                        <Space direction="vertical">
                                            <Radio value={'all'}>All</Radio>
                                            {
                                                subCats.map((sub, index) => {
                                                    return <Radio value={sub} key={index}>{sub}</Radio>
                                                })
                                            }
                                        </Space>
                                    </Radio.Group> */}
                                    {
                                        props.match.params.category === 'men' ?
                                            <ul className={styles.nav}>
                                                <span onClick={() => setSelectedSubCat('All shirts')}>All shirts</span>
                                                <li onClick={() => setSelectedSubCat('t-shirt')}>T-shirts</li>
                                                <li onClick={() => setSelectedSubCat('All-over')}>All-over shirts</li>
                                                <li onClick={() => setSelectedSubCat('3/4 sleeve shirts')}>3/4 sleeve shirts</li>
                                                <li onClick={() => setSelectedSubCat('Long sleeve shirts')}>Long sleeve shirts</li>
                                                <li onClick={() => setSelectedSubCat('embroidered sh')}>Embroidered shirts</li>
                                                <span onClick={() => setSelectedSubCat('jacket')}>Jackets</span>
                                                <span onClick={() => setSelectedSubCat('All hoodies')}>All hoodies and sweatshirts</span>
                                                <span onClick={() => setSelectedSubCat('All bottoms')}>All bottoms</span>
                                                <li onClick={() => setSelectedSubCat('sweatpant')}>{"Sweatpants & joggers"}</li>
                                                <li onClick={() => setSelectedSubCat('underwear')}>Underwear</li>
                                                <li onClick={() => setSelectedSubCat('leggin')}>Leggings</li>
                                                <li onClick={() => setSelectedSubCat('short')}>Shorts</li>
                                                <span onClick={() => setSelectedSubCat('swimw')}>Swimwear</span>
                                            </ul>
                                            : props.match.params.category === 'women' ?
                                                <ul className={styles.nav}>
                                                    <span onClick={() => setSelectedSubCat('All shirts')}>All shirts</span>
                                                    <li onClick={() => setSelectedSubCat('t-shirt')}>T-shirts</li>
                                                    <li onClick={() => setSelectedSubCat('All-over shirts')}>All-over shirts</li>
                                                    <li onClick={() => setSelectedSubCat('tank')}>Tank tops</li>
                                                    <li onClick={() => setSelectedSubCat('crop')}>Crop tops</li>
                                                    <span onClick={() => setSelectedSubCat('hoodie')}>All hoodies and sweatshirts</span>
                                                    <span nClick={() => setSelectedSubCat('All Bottoms')}>All Bottoms</span>
                                                    <li onClick={() => setSelectedSubCat('sweatpant')}>{"Sweatpants & joggers"}</li>
                                                    <li onClick={() => setSelectedSubCat('leggin')}>Leggings</li>
                                                    <li onClick={() => setSelectedSubCat('short')}>Shorts</li>
                                                    <span onClick={() => setSelectedSubCat('dresses')}>Dresses</span>
                                                    <span onClick={() => setSelectedSubCat('swim')}>Swimwear</span>
                                                    <span onClick={() => setSelectedSubCat('bra')}>Sport bras</span>
                                                    <span onClick={() => setSelectedSubCat('robes')}>Robes</span>
                                                </ul>
                                                : props.match.params.category === 'kids-&-youth' ?
                                                    <ul className={styles.nav}>
                                                        <span onClick={() => setSelectedSubCat('t-shirt')}>All shirts</span>
                                                        <li onClick={() => setSelectedSubCat('t-shirt')}>T-shirts</li>
                                                        <li onClick={() => setSelectedSubCat('All-over shirts')}>All-over shirts</li>
							<li onClick={() => setSelectedSubCat('sleeve shirts')}>3/4 sleeve shirts</li>
                                                        <span onClick={() => setSelectedSubCat('hoodie')}>Hoodies</span>
                                                        <span onClick={() => setSelectedSubCat('jacket')}>Jackets</span>
                                                        <span onClick={() => setSelectedSubCat('leggin')}>Leggings</span>
                                                        <span onClick={() => setSelectedSubCat('body')}>Baby bodysuits</span>
                                                        <span onClick={() => setSelectedSubCat('swim')}>Swimwear</span>
							<span onClick={() => setSelectedSubCat('Baby bibs')}>Baby bibs</span>
                                                    </ul>
                                                    : props.match.params.category === 'hats' ?
                                                        <ul className={styles.nav}>
                                                            <span onClick={() => setSelectedSubCat('dad')}>Dad hats / Baseball caps</span>
                                                            <span onClick={() => setSelectedSubCat('snap')}>Snapbacks</span>
                                                            <span onClick={() => setSelectedSubCat('trucker')}>Trucker hats</span>
                                                            <span onClick={() => setSelectedSubCat('panel')}>5-panel hats</span>
                                                            <span onClick={() => setSelectedSubCat('mesh')}>Mesh hats</span>
                                                            <span onClick={() => setSelectedSubCat('beanie')}>Beanies</span>
                                                            <span onClick={() => setSelectedSubCat('bucket')}>Bucket hats</span>
                                                            <span onClick={() => setSelectedSubCat('visor')}>Visors</span>
                                                        </ul>
                                                        : props.match.params.category === 'accessories' ?
                                                            <ul className={styles.nav}>
                                                                <span onClick={() => setSelectedSubCat('bag')}>Bags</span>
								<li onClick={() => setSelectedSubCat('Fanny packs')}>Fanny packs</li>
								<li onClick={() => setSelectedSubCat('Duffle bags')}>Duffle bags</li>
								<li onClick={() => setSelectedSubCat('Backpacks')}>Backpacks</li>
								<li onClick={() => setSelectedSubCat('Tote bags')}>Tote bags</li>
								<li onClick={() => setSelectedSubCat('Drawstring bags')}>Drawstring bags</li>
                                                                <span onClick={() => setSelectedSubCat('draw')}>Tech accessories</span>
                                                                <li onClick={() => setSelectedSubCat('Laptop cases')}>Laptop cases</li>
								<li onClick={() => setSelectedSubCat('Mouse pads')}>Mouse pads</li>
                                                                <li onClick={() => setSelectedSubCat('Phone cases')}>Phone cases</li>
                                                                <li onClick={() => setSelectedSubCat('Earphone cases')}>Earphone cases</li>
                                                                <span onClick={() => setSelectedSubCat('key')}>Keychains</span>
                                                                <span onClick={() => setSelectedSubCat('patches')}>Patches</span>
                                                                <span onClick={() => setSelectedSubCat('hair')}>Hair accessories</span>
                                                                <span onClick={() => setSelectedSubCat('footwear')}>Footwear</span>
                                                                <li onClick={() => setSelectedSubCat('Flip flops')}>Flip flops</li>
								<li onClick={() => setSelectedSubCat('shoes')}>Shoes</li>
								<li onClick={() => setSelectedSubCat('Socks')}>Socks</li>
                                                                <span onClick={() => setSelectedSubCat('Jewelry')}>Jewelry</span>
								<li onClick={() => setSelectedSubCat('Bracelets')}>Bracelets</li>
								<li onClick={() => setSelectedSubCat('Necklaces')}>Necklaces</li>
                                                                <li onClick={() => setSelectedSubCat('Earrings')}>Earrings</li>

                                                            </ul>
                                                            : props.match.params.category === 'home-&-living' ?
                                                                <ul className={styles.nav}>
                                                                    <span onClick={() => setSelectedSubCat('wall art')}>Wall art</span>
                                                                    <li onClick={() => setSelectedSubCat('poster')}>Posters</li>
                                                                    <li onClick={() => setSelectedSubCat('framed pos')}>Framed poster</li>
                                                                    <li onClick={() => setSelectedSubCat('Canvas prints')}>Canvas prints</li>
                                                                    <span onClick={() => setSelectedSubCat('drinkware')}>Drinkware</span>
								    <li onClick={() => setSelectedSubCat('Tumblers')}>Tumblers</li>
								    <li onClick={() => setSelectedSubCat('Mugs')}>Mugs</li>
                                                                    <span onClick={() => setSelectedSubCat('stationery')}>Stationery</span>
                                                                    <li onClick={() => setSelectedSubCat('postcards')}>Postcards</li>
                                                                    <li onClick={() => setSelectedSubCat('notebooks')}>Notebooks</li>
                                                                    <li onClick={() => setSelectedSubCat('sticker')}>Stickers</li>
                                                                    <span onClick={() => setSelectedSubCat('pillow')}>Pillow</span>
                                                                    <li onClick={() => setSelectedSubCat('Throw pillows')}>Throw pillows</li>
                                                                    <li onClick={() => setSelectedSubCat('Pillow cases')}>Pillow cases</li>
                                                                    <span onClick={() => setSelectedSubCat('blankets')}>Blankets</span>
                                                                    <span onClick={() => setSelectedSubCat('towels')}>Towels</span>
                                                                    <span onClick={() => setSelectedSubCat('aprons')}>Aprons</span>
                                                                    <span onClick={() => setSelectedSubCat('fabrics')}>Fabrics</span>
                                                                    <span onClick={() => setSelectedSubCat('Pet products')}>Pet products</span>
                                                                </ul>
                                                                :
                                                                null

                                    }
                                </Col>
                                <Col sm={15}>
                                    <section className={'container ' + styles.images}>
                                        <Row gutter={[36, 36]}>
                                            {

                                                products.map((product, index) => {
                                                    return <>
                                                        {
                                                            selectedSubCat === 'all' ?
                                                               testcat(Number(product.category)) && product.price?
                                                                    <Col xs={12} sm={6} onClick={() => setSelectedProduct(product)} >
                                                                        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                                                            <div className={styles.images_wrapper}>
                                                                                <img src={product.design} alt="" />
                                                                            </div>
                                                                            <span>{product.name}</span>
                                                                             USD ${(product.price*1.6).toFixed(2)}
                                                                        </div>
                                                                    </Col>
                                                                    :
                                                                    null
                                                                :
                                                                 testcat1(Number(product.category)) && product.price?
                                                                    <Col xs={12} sm={6} onClick={() => setSelectedProduct(product)} >
                                                                        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                                                            <div className={styles.images_wrapper}>
                                                                                <img src={product.design} alt="" />
                                                                            </div>
                                                                            <span>{product.name}</span>
                                                                            USD ${(product.price*1.6).toFixed(2)}
                                                                        </div>
                                                                    </Col>
                                                                    :
                                                                    null

                                                        }
                                                    </>
                                                })
                                            }
                                        </Row>
                                    </section>
                                </Col>
                            </Row>
                        </section>
                        <br />
                        <br />
                    </>
            }

            {
                selectedProduct &&
                <ProductModal
                    product={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                />
            }
        </Layout >
    );
};

export default Category;