import { useEffect, useState, useContext } from 'react';
import styles from './ProductModal.module.scss';
import axios from '../../config/axios';
import {FaCheck } from 'react-icons/fa';
import { Link } from 'react-router-dom'
import {
    Modal,
    Row,
    Col,
    message
} from 'antd';
import { GlobalStates } from '../../Contexts/GlobalStates';

const ProductModal = ({ product, setSelectedProduct }) => {
     const [productData, setProductData] = useState([]);
     const [colorData, setColorData] = useState([]);
     const [sizeData, setSizeData] = useState([]);
     const [selSize, setSelSize] = useState(null);
     const [selColor, setSelColor] = useState(null);
     const [image, setImage] = useState(null);
     const [view, setView] = useState(false);
     const [variantId, setVariantId] = useState(null);
     const [price, setPrice] = useState(null);
     const [selProduct, setSelProduct] = useState('');
     useEffect(() => {
	if(product){
		
		axios.get(`/printful/products/${product.product_id}`).then(response => {
		    setProductData(response.data.result.variants);
		    setSelProduct(response.data.result.product);
		    var i;
		    var color = []
		    var size = []
		    for( i = 0; i < response.data.result.variants.length; i++){
		    	if(!color.includes(response.data.result.variants[i].color_code)){
				color = color.concat(response.data.result.variants[i].color_code)
			}
			if(!size.includes(response.data.result.variants[i].size)){
				size = size.concat(response.data.result.variants[i].size)
			}
		    }
		    setColorData(color)
		    setSizeData(size)
		    if(!selColor){
		    	setSelColor(response.data.result.variants[0].color_code);
		    }
		    if(!selSize){
		    	setSelSize(response.data.result.variants[0].size);
		    }
		    if(!image){
		    	setImage(product.design)
		    }
		    setView(true)
		});
		
	 }
    }, []);
    useEffect(() => {
    	var i;
	if(productData.length > 0 ){
	       for( i = 0; i < productData.length; i++){
		    	if(productData[i].color_code == selColor && productData[i].size == selSize){
				setImage(productData[i].image)
				setVariantId(productData[i].id)
				var temp = productData[i].price*1.6;
				setPrice(temp.toFixed(2))
			}
		}
	 }
    }, [selColor, selSize]);
    
    const { setGRefresh } = useContext(GlobalStates);
    return (
        <Modal
            visible={product ? true : false}
            footer={null}
            width={'80vw'}
            centered={true}
            onCancel={() => {setSelectedProduct(null); setView(false);}}
        >
            <Row className={styles.prod} gutter={[15, 15]} style={{ marginTop: '2rem' }}>
                <Col xs={24} sm={12}>
                    <img className={styles.img} src={image} alt="" />
                </Col>
                <Col xs={24} sm={12}>
                    <div className={styles.info}>
                        <span>{selProduct.title}</span>
                        <p>{selProduct.description}</p>
                        <p>Size: </p>
			<div style={{ display: 'flex', flexWrap: 'wrap' }}>
			 {
			 	view ?
				sizeData.map((variant, index) => {
                                return <span
				      className={styles.size}
                                      style={{
                                            backgroundColor: variant === selSize ? 'grey' : null,
                                            color: null,
                                            width: 'fit-content',
                                            padding: '.3rem'
                                       }}
				        onClick={() => { setSelSize(variant) }}
					 >
					 {variant}
					  </span>
                                  })
				  :
				  null
                             }
                              </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <p>Color: </p>
			   </div>
                         <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {
				  view ?
                                     colorData.map((variant, index) => {
					return <span
					      className={styles.color}
					      style={{
						    backgroundColor:  variant,
					       }}
					       onClick={() => { setSelColor(variant) }}
						 >
						 {
                                                   selColor === variant  &&
                                                                <FaCheck size={10} color={variant=='#ffffff'?'black':'white'} />
                                                   }
						</span>
                                  })
				   :
				   null
                              }
                          
                        </div>
			<div style={{ display: 'flex', alignItems: 'center' }}>
                            <p>Price: USD ${price}</p>
			</div>
                    </div>

                    <div className={styles.button_box}>

                        <button onClick={() => {
                            let prod = selProduct;
                            prod.quantity = 1;
			    prod.design = image;
			    prod.name = selProduct.title;
			    prod.price = price;
			    prod.variant_id = variantId;
                            if (localStorage.getItem('cartData')) {
                                let data = JSON.parse(localStorage.getItem('cartData'));
                                data.push(prod);
                                localStorage.setItem('cartData', JSON.stringify(data));
                            } else {
                                localStorage.setItem('cartData', JSON.stringify([prod]));
                            }
                            message.success('Product added to cart successfully!');
                            setGRefresh(Math.random())
                        }}>Add to cart</button>

                        <Link to={`/mockup/${selProduct.id}?image=${image}&variant=${variantId}`}>
                            <button>Design Your Own</button>
                        </Link>
                    </div>
                </Col>
            </Row>
        </Modal>
    );
};

export default ProductModal;