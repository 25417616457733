import styles from './Users.module.scss';
import React, { useEffect, useState } from 'react';
import { Button, Image,  Switch, message, Table } from 'antd';
import axios from '../../config/axios';

const Users = () => {

    const [dataSource, setDataSource] = useState([]);


    useEffect(() => {
         axios.get('/users').then(response => {
	 	
		var i;
		var temp = [];
		for(i=0; i<response.data.length; i++)
		{
			temp.push({no: i+1, 
			first_name: response.data[i].first_name,
			last_name: response.data[i].last_name,
			email: response.data[i].email,
			date_joined: response.data[i].date_joined.substring(0,10),
			is_active: response.data[i].is_active,
			is_superuser: response.data[i].is_superuser,
			id: response.data[i].id})
		}
	        setDataSource(temp)
        });
    }, []);

    const columns = [
    	{
            title: 'No',
            dataIndex: 'no',
            key: 'no',
        },
        {
            title: 'First Name',
            dataIndex: 'first_name',
            key: 'first_name',
        },
	{
            title: 'Last Name',
            dataIndex: 'last_name',
            key: 'last_name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
	{
            title: 'Join Data',
            dataIndex: 'date_joined',
            key: 'date_joined',
        },
	{
	    title: 'Admin',
	    key: 'is_superuser',
	    dataIndex: 'is_superuser',
	    render: (_, record) => (
		  <Switch defaultChecked={record.is_superuser} onChange={value => {
                     axios.patch(`/users/${record.id}`, { is_superuser: value }).then(response => {
			if (value) {
				message.success('Superuser Enable!');
			} else {
				 message.success('Superuser Disable!');
			}
                      })
                 }} />
	    ),
	  },
	 {
	    title: 'Approved',
	    key: 'is_active',
	    dataIndex: 'is_active',
	    render: (_, record) => (
		  <Switch defaultChecked={record.is_active} onChange={value => {
                     axios.patch(`/users/${record.id}`, { is_active: value }).then(response => {
			if (value) {
				message.success('User Enable!');
			} else {
				 message.success('User Disable!');
			}
                      })
                 }} />
	    ),
	  },
    ];
  
    return (
    <div>
       <h1 style={{ marginTop: '1rem' }}>Users List</h1>
       <Table dataSource={dataSource} columns={columns} />
  </div>
    );
};

export default Users;