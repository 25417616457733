import { useEffect, useContext, useState } from 'react';
import styles from './ProductModal1.module.scss';

import { Link } from 'react-router-dom'
import {
    Modal,
    Card,
    Col,
    Row,
    Typography,
    Tooltip,
    Progress,
    Upload,
    Button,
    Timeline,
    Radio,
    message
} from 'antd';
import { GlobalStates } from '../../Contexts/GlobalStates';
const ProductModal1 = ({ product,mockup,emockup, setSelectedProduct }) => {
    const [index, setIndex] = useState(1);
    const [dataarray, setDataarray] = useState([]);
    const [temparray, setTemparray] = useState([]);
    const { Title, Text } = Typography;
    const { setGRefresh } = useContext(GlobalStates);
    useEffect(() => {
    if(mockup){
      for (let i = 0; i < mockup.variant_mapping[0].templates.length; i++) {
        for (let j = 0; j < mockup.templates.length; j++) {
          if(mockup.variant_mapping[0].templates[i].template_id === mockup.templates[j].template_id){
            let temp = (mockup.templates[j].print_area_height*2.54/mockup.min_dpi).toFixed(2)+"*"+(mockup.templates[j].print_area_width*2.54/mockup.min_dpi).toFixed(2)+ " cm";
            let temp2 = mockup.variant_mapping[0].templates[i].placement;
            dataarray.push([temp2,"All sizes", temp]);
          }
        }
      }
      }
      if(emockup){
      for (let i = 0; i < emockup.variant_mapping[0].templates.length; i++) {
        for (let j = 0; j < emockup.templates.length; j++) {
          if(emockup.variant_mapping[0].templates[i].template_id == emockup.templates[j].template_id){
            let temp = (emockup.templates[j].print_area_height*2.54/mockup.min_dpi).toFixed(2)+"*"+(emockup.templates[j].print_area_width*2.54/mockup.min_dpi).toFixed(2)+ " cm";
            let temp2 = emockup.variant_mapping[0].templates[i].placement;
            dataarray.push([temp2,"All sizes", temp]);
          }
        }
      }
      }
    }, [])
    return (
        <Modal
            visible={product ? true : false}
            footer={null}
            width={window.innerWidth >= 576 ? '60vw' : '95vw'}
            centered={true}
            onCancel={() => setSelectedProduct(null)}
        >
           
              <div className="project-ant">
                <div>
                  <Title level={4}>{product.title}</Title>
                </div>
                
               
                  <div   className={styles.button_box}>
		    <button  style={{ border: index === 1 ? '2px solid var(--color-blue)' : null}} onClick={() => setIndex(1)}>Product info</button>&nbsp;
                    <button className={styles.button_box} style={{border: index === 2 ? '2px solid var(--color-blue)' : null}} onClick={() => setIndex(2)}>File templates</button>


                  </div>
                  <br></br>
           
              </div>
              
              <div style={{ 
                            display: index==2 ? 'none' : null,
                            width: '100%',
                            textAlign: 'left',
                            marginRight: '3rem',
                            paddingBottom: '.2rem'
                }}  dangerouslySetInnerHTML={{ __html: product.description.replaceAll('\n', '<br/>' ) }} />
                
              <div style={{ 
                            display: index==1 ? 'none' : null
                }}>
                <table width="100%" border="1" style={{overflowWrap: 'anywhere'}}>
                  <thead>
                    <tr align="center">
                      <th width="30%">Placement</th>
                      <th width="18%">Sizes</th>
                      <th width="27%">Print file size</th>
                      <th width="25%">Print file template</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      dataarray.map((option, index) => {                        
                        return <tr align="center">
                            <th>{option[0]}</th>
                            <th>{option[1]}</th>
                            <th>{option[2]}</th>
                            <th></th>
                        </tr>
                        })
                     }
                  </tbody>
                </table>
              </div>

         
        </Modal>
    );
};

export default ProductModal1;