import { useContext, useState } from 'react';
import styles from './ProductModal1.module.scss';
import { Link } from 'react-router-dom'
import { Modal, Row, Col,  Radio, Switch, message} from 'antd';
import { GlobalStates } from '../../Contexts/GlobalStates';
import { BsCartFill, BsFacebook, BsYoutube, BsSunsetFill, BsSunrise } from 'react-icons/bs';
const ActionAlert = ({ blackAction,blackActionView, setBlackAction, setBlackActionView }) => {
    const [agree, setAgree] = useState(blackAction);
    const View = ()=>{
	if(agree){
		setAgree(0);
		setBlackAction(0);
	}else{
		setBlackAction(1);
		setAgree(1)
	}
    }
    return (
        <Modal
            visible={blackActionView ? true : false}
            footer={null}
            width={window.innerWidth >= 576 ? '50vw' : '60vw'}
            centered={true}
            onCancel={() => setBlackActionView(0)}
        ><br></br>
	<div>
	<Row>
		<Col sm={4}>
		<div class="">
			<img src="/action_logo.png" style={{ width: '50px', height: '50px' }} className={styles.the_image} />
		</div>
		</Col>
		<Col sm={20}>
		<div class="">
		<p><font size='20px'><b>&nbsp;&nbsp; What your print will look like</b></font></p>
		<p>&nbsp;&nbsp; This mockup shows what your design will look like once it gets printed. You can turn this feature off if you want. Here is <a href="../BlackView" target="blank"><font color='blue'> why it might look different</font></a> from your original design.</p>
		</div>
		<div>		 
			<Switch
                            size={15}
                            checked={agree === 1 ? true : false}
                            onChange={View}
                        />

		</div>
		</Col>
	</Row>
	</div>
        </Modal>
    );
};

export default ActionAlert;