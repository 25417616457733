import Layout from '../../Components/Layout/Layout';
import styles from './Embsample.module.scss';
import { useEffect } from 'react';

const Embsample = () => {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 500);
    }, []);
    return (
        <Layout
            title={'How it works - Unme'}
        >
            <section className={styles.how + " " + "container"}>

		<h2>Embroidery Sample</h2>
                <p><img src="https://files.cdn.printful.com/o/upload/variant-image-jpg/fa/fa53f678fd22f485d0dcddfad8552169_l?v=057b983350fc95d77a4a0dcfb8a6005a" alt="3Dembroidery sample" width="90%"/></p>

            </section>
        </Layout>
    );
};

export default Embsample;