import Layout from '../../Components/Layout/Layout';
import styles from './Guidelines.module.scss';
import { Row, Col } from 'antd';
import { useEffect } from 'react';

const Guidelines = () => {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 500);
    }, []);
    return (
        <Layout
            title={'How it works - Unme'}
        >
            <section className={styles.how + " " + "container"}>


                <h2>Content Guidelines</h2>
                <p>If you will participate in the “Earn crypto” initiative, please note that we follow specific guidelines in order to preserve the overall respect to others and to the law. Content that displays, hatred, racism, violence and/or content that is illegal or that promotes illegal activity (i.e., obscenity, child pornography, etc.,) will not be permitted. Content that violates intellectual property rights (i.e., Trademark or Copyright) may be reviewed and removed at any time.</p>
                <h2>Privacy & Refund Policy</h2>
                <p>We do not share or sell your information to third parties. The data you provide will be solely used to process your order. </p>
                <p>{`One core aspect of our business-purpose is to protect the consumers, that is why we implement a smart-escrow construction > to give the chance and option of receiving-the-item-first before releasing the funds as final payment. If you notice any damage on the received item please click on the < Dispute> button within 72 hours after the product has been received. That will put you in direct contact with one of our agents to look into the issue and find a solution to it. We strongly recommend you to contact us before returning any product(s). However, a second layer of protection allows you to submit any claim for defective or misprinted item(s); if any, such claim must be submitted within 4 weeks after the product has been received. Claims deemed an error on our part are covered 100% at our expense. Cases that will exempt us from being liable for reshipments or refund:`}</p>
                <h2>Wrong Address</h2>
                <p>                If you provide an address that is considered insufficient by the courier the shipment will be returned to the facility. Please make sure you provide the correct address and with no mistakes or misspellings.</p>
                <h2>Unclaimed</h2>
                <p>Shipments that go unclaimed will return back to the facility.</p>

                <h2>Returned by Customer:</h2>

                <p>Unme does not accept returns of sealed goods, such as but not limited to face masks, which are not suitable for return due to health or hygiene reasons. You hereby agree that returned orders with face masks won’t be available for reshipping and will be disposed of.</p>

                <p>We do not refund or exchange orders for buyer’s remorse and/or returns for products, and we don’t do size exchange.</p>

                <p>Customers residing in Brazil and regretting a purchase must contact our Customer Service and express their will to return the item within 7 consecutive days after receiving it, providing a picture of the item. The withdrawal request will undergo an evaluation to verify whether the product was used or destroyed, even if partial. In these cases, a refund will not be possible.</p>
                <h2>Notification for EU consumers:</h2>

                <p>According to Article 16(c) of the Directive 2011/83/EU of the European Parliament and the Council of 25 October 2011 on consumer rights, the right of withdrawal may not be provided for:</p>
                <p>● The supply of goods that are made to the customer’s specifications or that are clearly personalized.</p>
                <p>● Sealed goods which were unsealed and thus aren't unsuitable for return due to health protection or hygiene reasons.</p>
                <p>Therefore Unme reserves the rights to refuse returns at its sole discretion.</p>
                <p>Regardless of any translation, this Policy shall be governed and interpreted in accordance of the English language.</p>

                <h2>Terms of Service</h2>
                <h2>1. Access:</h2>
                <p>If you are an individual (rather than an organization or entity), then you certify to Unme that you are at least 18 years of age, however, if you are an individual residing in Japan, you certify to Unme that you are at least 20 years of age.</p>
                <h2>2. Modifications:</h2>
                <p>We reserve the right to modify our terms, services, and fees. Please follow changes to our terms by checking in with the Policy updates section on our website.
                    Without affecting orders for European Economic Area Users which Unme has already confirmed Unme reserves the right, at its sole discretion, to modify this Agreement and its terms, as well as the fees and other charges for our Services, at any time.  All such modifications will become effective immediately after Unme posts them on the Site.</p>
                <h2>3. Content:</h2>
                <p>Unme respects intellectual property rights and asks you to do the same. Anything you upload will remain yours, and you're responsible for it. We'll only use the content you submit to provide you with our services. If we receive a Digital Millennium Copyright Act (DMCA) takedown notice for content you've submitted, we may remove the allegedly infringing content or suspend access to the Unme website.</p>
                <p>All content (including all information, images, pictures, data, text, photographs, graphics, messages, and other materials, hereinafter "Content") that you post, submit, upload, display, sell or use, hereinafter "post", using our Services is your content. We don’t make any claim(s) to it. That includes anything you post using our Services (like your Content, images, customer reviews, comments, videos, usernames, etc.).</p>
                <h2>* Responsibility for the Content:</h2>
                <p>You understand that you are solely responsible for the Content that you post on or through the Services. You represent that you have all necessary rights to the Content, including all necessary rights to post it or use it on your Products with Purposes sold, manufactured, by Unme. You also represent that you’re not infringing or violating any third party’s rights by posting the Content or using the Content on your Products sold or manufactured by Unme.</p>
                <h2>* Permission to Use the Content:</h2>
                <p>Unme agrees that any Content that you post using our Services will remain yours. This means that we will never use your Content except with your express permission or as otherwise provided in this Agreement.</p>
                <h2>* Rights You Grant to Unme:</h2>
                <p>By posting your Content, you grant Unme a non-exclusive, worldwide, royalty-free, irrevocable, sub-licensable, perpetual license to use, display, edit, modify, reproduce, distribute, and prepare derivative works of your Content to provide the Services and to promote Unme and/or your Unme displays of Products with Purpose, or the Services in general, in any formats and through any channels, including across any Unme Services, third-party website, advertising medium and/or social media. You agree and represent that you have the rights to grant this license to us.</p>
                <h2>* Reporting Unauthorized Content:</h2>
                <p>Unme respects intellectual property rights and follows intellectual property laws. We are committed to following appropriate legal procedures to remove infringing content from the Service(s).</p>
                <h2>* Intellectual Property:</h2>
                <p>Unme respects your work and empowers you to express your voice and ideas. We ask that you respect the work and creative rights of others. You need to either own the Content you post to Unme, or have the express authority to post it. Content must comply with right of publicity, trademark and copyright laws, and all other applicable state and federal laws.</p>
                <h2>* Trademarks and Infringement of Intellectual Property:</h2>
                <p>We are committed to following appropriate legal procedures to remove infringing content from the Services. Unme will try to accommodate and not interfere with standard technical measures used by copyright owners to identify and protect their works. Unme reserves the right to do any or all of the following at any time at our sole discretion: (i) immediately suspend your use of the Services; and/or (ii) remove, block, and disable access to any of your Content that is alleged to infringe the intellectual property rights of others. When we notice of intellectual property infringement (via the form available here), Unme strives to respond quickly by removing, blocking, or disabling access to the allegedly infringing material. When Unme removes, blocks or disables access in response to such a notice, Unme makes a reasonable attempt to contact the allegedly infringing party, provide information about the notice and removal, and, in cases of alleged copyright infringement, provide information about counter notification.</p>
                <p>A) DMCA Cooperation:  Unme’s copyright infringement notice and Content removal policies and procedures are intended to comply with the requirements and obligations of service providers under the Digital Millennium Copyright Act (DMCA).  To the extent that any requirements and obligations of service providers under the DMCA change or that are different than set forth herein, Unme’s policies and procedure may likewise change in accordance with the DMCA requirements and obligations of service providers. More information about DMCA procedure is available here.</p>
                <p>B) Counter DMCA Notifications: If Unme receives a DMCA counter notification, Unme will send a copy of the counter notice to the original complaining party.  Unless the copyright owner files an action seeking a court order against the allegedly infringing party and informs Unme of this action, the removed, blocked, or disabled material may be replaced or access to it may be restored 10-14 business days after delivery of the counter notice to the original complaining party.  If an action is filed, any Content removed, blocked, or disabled shall remain removed, blocked or disabled at the sole discretion of Unme.</p>
                <p>C) Repeat Infringement: Unme may block the access to the privileges of participants that are subject to repeat notices of intellectual property infringement as determined by Unme at its sole discretion.</p>
                <h2>* Copyright and Trademark Responsibility:</h2>
                <p>You agree and represent by accepting this Agreement and using our Services that you own all rights (including copyrights) for the Content you post, or if you are not the owner, that you have express authority and written permission to use and reproduce the Content in connection with the Services, and that you have all of the rights required to post your Content. Likewise, by accepting this Agreement and using our Services, you agree and represent that you own all trademarks, service marks, trade dress, and trade names incorporated into the Content you post, or if you are not the owner, that you have express authority and written permission to use, post, and reproduce such marks, trade dress, and trade names in connection with any Content and the Services provided under this Agreement.</p>
                <h2>* Inappropriate, False, or Misleading Content:</h2>
                <p>This should be common sense, but there are certain types of Content we don’t want submitted to our Services (for legal reasons or otherwise). You agree that you will not post any content that is abusive, threatening, defamatory, obscene, vulgar, illegal or otherwise offensive or in violation of any part of this Agreement. You also agree not to post any Content that is false or misleading or uses the Services in a manner that is fraudulent or deceptive.</p>
                <h2>4. Use of Services</h2>
                <h2>* Don’t Use Our Services to Break the Law:</h2>
                <p>You agree that you will not violate any laws in connection with your use of the Services. This includes any local, state, federal, and international laws that may apply to you or Unme.</p>
                <p>You must not engage in fraud, theft, anti-competitive conduct, threatening conduct, or any other unlawful acts or crimes against Unme, another Unme user, or a third party. You also agree that you are not the target of trade, financial, and economic sanctions, and that you do not appear on a sanctions-related list, including lists maintained by the U.S. Department of Treasury’s Office of Foreign Assets Control (“OFAC”), the U.S. Department of State, the U.S. Department of Commerce, the European Union, or Her Majesty’s Treasury of the United Kingdom. You also agree that you will not export, re-export, or otherwise transfer Unme Products with Purpose to countries or territories that are the target of comprehensive embargoes or sanctions (including Cuba, Iran, Syria, North Korea, and the Crimea region) or parties on the sanctions-related lists referenced above.  You agree not to use the Services: (i) to impersonate or attempt to impersonate Unme; or (ii) for the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.</p>
                <h2>* Don’t Try to Harm Our Systems:</h2>
                <p>You agree not to interfere with or try to disrupt our Services, for example by distributing a virus or other harmful computer code into our platform.  You agree not to use the Services: (i) to send, knowingly receive, upload, download, use, or re-use any material which does not comply with this Agreement; or (ii) to engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Services, or which, as determined by us, may harm Unme or any Unme Party or expose any of them to liability.  You agree that unme reserves the right to audit our platforms (systems) to ensure integrity and compliance with this Agreement, at the sole discretion of Unme.</p>
                <h2>5. Limited Warranty on Products</h2>
                <p>Summary: Please read this section very carefully—it covers our obligations and responsibilities for Unme services. If you have an issue with your order, you may be eligible for a replacement product or refund, so reach out to us as soon as possible.</p>
                <p>Does not apply to Users residing in the European Economic Area.</p>
                <h2>* Limited Warranty:</h2>
                <p>We warrant that, at the time of delivery of a Product with Purpose to a User / Customer, the Product will not be materially defective or damaged (the “Limited Warranty”). Customers residing in Brazil have the right to cancel and return their order within 7 days, for any reason.</p>
                <h2>* Who May Use This Warranty?</h2>
                <p>Unme extends the Limited Warranty only to Users / Customers.  All Limited Warranty coverage terminates if the User / Customer sells or otherwise transfers a Product with Purpose.</p>
                <h2>*What Does This Warranty Not Cover?</h2>
                <p>This Limited Warranty does not cover any damage to or defect in a Product with Purpose caused by any of the following: (a) you or third parties; (b) any improper handling (including during shipping), use or storage of the Product with Purpose; (c) any failure to follow any Product with Purpose instructions; (d) any modifications to the Product with Purpose; (e) any unauthorized repair to the Product with Purpose; or (f) any external causes such as accidents, fire, flood, “acts of God” or other actions or events beyond our reasonable control; or (g) any costs or expenses related to the loss of use of the Product with Purpose or any other costs or expenses not covered by this Limited Warranty.  This Limited Warranty does not cover any items supplied by third parties, size exchanges or buyer’s remorse.</p>
                <p>Finally, as noted elsewhere in this Agreement, we unfortunately cannot guarantee that the colors and details in our website images are 100% accurate representations of a Product with Purpose, and sizes might in some cases be approximate.  Accordingly, this Limited Warranty does not cover such matters.</p>
                <h2>*What Is The Period Of Coverage?</h2>
                <p>This limited warranty starts on the date of the delivery of the Product to the User or the Merchant’s Customer and lasts for time the funds will be held inside the smart-escrow (days of the “Warranty Period”). The smart-escrow allows the User / Customer to only release the funds after the merchandise (Product with Purpose) has been delivered and inspected by the User / Customer, therefore only after inspection the User / Customer would release the funds inside the smart-escrow. If the User / Customer does not release the funds after receiving the merchandise (Product with Purpose) because User / Customer has forgotten or because would be acting in bad faith, the system then will automatically release the funds 48 hours after the merchandise has been delivered. For the avoidance of doubt, Unme acknowledges that according to some jurisdictions the User / Customer may be entitled to a longer Warranty Period.</p>
                <h2>* What Are Your Remedies Under This Warranty?</h2>
                <p>With respect to any materially defective or damaged Product, we will, in our sole discretion, either: (a) replace such Product (or the defective or damaged part of the Product) free of charge, or (b) refund the purchase price paid to us by the User / Customer along with the shipping fees corresponding to the defective or damaged Products.</p>
                <h2>* How Do You Obtain Warranty Service?</h2>
                <p>Promptly following delivery of a Product, you the User / Customer shall inspect the Product with Purpose.  If a Product with Purpose is materially defective (including any error by Unme in printing) or damaged upon receipt, or if you received the wrong Product with Purpose, then in order to be eligible for service under this Limited Warranty, you must submit a claim within the Warranty Period in compliance with our Return Policy.</p>
                <h2>6. Your rights in the case of lack of conformity:</h2>
                <p>If you reside in the European Economic Area, this is where you'll find information on what you can do if there's an issue with your order.</p>
                <p>If you are a User residing in the European Economic Area, the legal guarantees established by Directive 1999/44/EC on consumer sales and guarantees below apply to the sale of our products. Consequently, you have the right, free of charge, to get the products repaired (or replaced) within the limits provided by the law, or, in case of failure of one of the remedies above to have an appropriate reduction in the price of the products, or the contract cancelled. Delivery costs for returning the product to be repaired or replaced under this clause shall be borne by Unme, as well as any costs related to the delivery to you of the repaired or replaced product.</p>
                <h2>7. Shipping:</h2>
                <p>Once you've placed an order, you might no longer be able to edit the order details or cancel it. Please make sure to input the right address destination before placing your final order.</p>
                <p>The risk of loss of, damage to and title for Products with Purpose pass to you upon our delivery to the carrier. It shall be you the User / Customer's responsibility to file any claim with a carrier for a lost shipment if carrier tracking indicates that the Product with Purpose was delivered. In such case Unme will not make any refunds and will not resend the Product with Purpose. For Users in the European Economic Area the risk of loss of, damage to and title for Products with Purpose will pass to you when you or a third party indicated by you has acquired the physical possession of the Products with Purpose.</p>
                <p>If carrier tracking indicates that a Product with Purpose was lost in transit, you (the User / Customer) may make a written claim for replacement of (or return funds to the User / Customer) the lost Product with Purpose in compliance with Unme’s Return Policy. For Products with Purpose lost in transit, all claims must be submitted no later than 30 days after the estimated delivery date.  All such claims are subject to Unme investigation and sole discretion.</p>
                <h2>8. Description of Products with Purpose:</h2>
                <p>               We strive towards making our product development process the best we can, yet we can't guarantee that the product representations on our website will be 100% accurate or that the manufacturing stage won't result in damages. To speed up fulfillment, we also offer you product alternatives in case any of your products are out of stock.</p>
                <p>While many component parts of our Products with Purpose are standard, all Products with Purpose available for purchase are described on their specific page on our Site. We always try to represent each design as accurately as possible via photography and copy points provided by designers, artists or photographers.</p>
                <p>We have a policy of continuous Product with Purpose development so we can provide you with what we consider the best design combined with best performance, and thus reserve the right to amend the specifications of Products with Purpose, their price, packaging, and any Service associated at any time, without prior notice. Before ordering, we invite you to have a close look at the Product with Purpose description and design.</p>
                <p>We use our best efforts to provide you with the best images and descriptions, but unfortunately cannot guarantee that colors and details in website images are 100% accurate representations of the product, and sizes might in some cases be approximate.</p>
                <p>Sometimes during the manufacturing process Products with Purpose can be damaged.  Obviously we won’t knowingly ship damaged items to you, but these damaged items can still be used for charitable purposes. Unme reserves the right to donate all damaged items with full or partial designs to charity.</p>
                <h2>9. Purchase of Products with Purpose:</h2>
                <p>To make sure your orders arrive as quickly as possible, please make sure you submit the correct order details at checkout. Unme doesn't take responsibility for missed deliveries due to typos in the delivery information.</p>
                <p>Your order is a purchase of a Product with Purpose for which you have paid the applicable fee and/or other charges that we have accepted and received. Any Products with Purpose in the same order which we have not accepted do not form part of that contract.  We may choose not to accept any orders in our sole discretion.</p>
                <p>Orders are placed and received exclusively via the Site. Before ordering from us, it is your responsibility to check and determine full ability to receive the Products with Purpose. Correct name of the recipient, delivery address and postal code/zip code, up-to-date telephone number, and email address are absolutely necessary to ensure successful delivery of Products with Purpose.</p>
                <p>All information asked on the checkout page must be filled in precisely and accurately. Unme will not be responsible for missed delivery because of a wrong or misspelled recipient name or surname, delivery address or an inappropriate phone number. Should you like to ask for a change in the delivery address, phone number, or any other special requirement(s), please contact us.</p>
                <p>We reserve the right to place your order on hold due to print file issues, security concerns, if we need to confirm your address, or in case of other issues. In case your order will be placed on hold, we will give you 30 days to resolve the issue. If you will not resolve the issue or cancel your order within 30 days, we will cancel the order. In case your order is cancelled, we will only make a refund if work on your order has not started yet.</p>
                <h2>10. Delivery:</h2>
                <p>While we may provide delivery estimates, we can't provide guaranteed delivery dates. Once Unme receives your payment for your order (including delivery fees), we fulfill the order and pass it onto the carrier. This is also the moment when you (the User / Customer) legally become the owner of the Products with Purpose.</p>
                <p>We deliver to most places in the world. You shall cover delivery costs. Delivery prices are additional to the Product’s with Purpose price and may vary depending on delivery location and/or sort of Products, and additional charges may be added to the order for remote or difficult to access locations that require special attention. Flat rate delivery charges are shown on our checkout page; however, we reserve the right to advise you of any additional delivery charges that apply to your specific delivery address.</p>

                <p>Some Products with Purpose are packaged and shipped separately. We cannot guarantee delivery dates and to the extent permitted by law accept no responsibility, apart from advising you of any known delay, for Products with Purpose that are delivered after the estimated delivery date. Average time for delivery may be shown on the Site. It is only an average estimation, and some delivery can take longer, or alternatively be delivered much faster. All delivery estimates given at the time of placing and confirming order can be subject to change. In any case, we will do our best to contact you and advise you of all changes. We try our best to make Product delivery as simple as possible.</p>
                <p>Ownership of the Products with Purpose will only pass to you (the User / Customer) after we receive full payment of all sums due in respect of the Products with Purpose, including delivery charges and taxes, and deliver the Products with Purpose to the carrier.</p>
                <h2>11. Release:</h2>
                <p>You can’t sue us for anything that we have disclaimed or obtained your release from under this Agreement.</p>
                <p>To the fullest extent permitted by law you release Unme and from any and all claims and demands, as well as any and all damages, losses, liabilities, judgments, costs, reasonable attorneys' fees, and other expenses incurred or suffered of every kind and nature, known and unknown, relating to or arising out of any right, claim, or matter (a) which is disclaimed by Unme (or for which Unme provides no guarantees) under this Agreement, or (b) for which Unme is otherwise indemnified or released by you under this Agreement.</p>
                <h2>12. Indemnity:</h2>
                <p>Unme is not responsible for any damages and liabilities you incur if you break the law, violate these terms or any rights of third parties and it results in legal action (this also includes misrepresentations made by you and any product liability claims).</p>
                <p>To the fullest extent permitted by law you will defend, indemnify, and hold Unme harmless from any claim or demand made by any third party, as well as any and all damages, losses, liabilities, judgments, costs, reasonable attorneys' fees, and other expenses of every kind and nature, known and unknown, incurred or suffered by Unme, relating to or arising out of (a) your breach of this Agreement, (b) your use (or misuse) of our Services, (c) your Content, (d) the infringement by your Content or your account of someone else’s intellectual property or other rights, (d) your violation of any law or the rights of a third party, or (e) if you are a Creator, any claims related to Products with Purpose or items sold through our Services, including claims for misrepresentations.  We reserve the right to handle our legal defense however we see fit, even if you are indemnifying us, in which case you agree to cooperate with us so we can execute our strategy.</p>
                <h2>13. Privacy and Personal Data Processing:</h2>
                <p>Unme Privacy Policy is an important part of our Terms of Service that explains how your information is used when you use our services. Please read it very carefully.</p>
                <p>Unme collects your personal data to provide our Services. We are committed to protecting your personal data and privacy and our Privacy Policy details and explains how we are processing Site visitor and User personal information. By accepting this Agreement, you are also accepting and acknowledging our Privacy Policy.</p>
                <h2>14. General:</h2>
                <p>Using our services doesn't make you an agent, partner or employee of Unme. We're not responsible for any violations of these terms if it's out of our control. If you have any questions about our Terms of Service, feel free to contact us at (we have to add our email).</p>
                <p>No agency, partnership, joint venture, employee-employer, franchisor-franchisee relationship, etc. is intended or created by this Agreement.</p>
                <p>Unme will not be liable or responsible to you, nor be deemed to have defaulted or breached this Agreement, for any failure or delay in Unme’s performance under this Agreement when such failure or delay is caused by or results from acts or circumstances beyond our reasonable control, such as any fire; flood; earthquake; governmental action; war, invasion or hostilities; national emergency; explosion; terrorist threat or act; riot or other civil unrest; insurrection; epidemic; lockout, strike or other labor dispute (whether or not relating to our workforce); inability or delay in obtaining supplies; telecommunication breakdown; or power outage.</p>
                <p>If any provision of this Agreement is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall be enforced. In our sole discretion, we may assign this Agreement upon notice to you. Headings are for reference purposes only and do not limit the scope or extent of such section. Our failure to act with respect to a breach by you or others does not waive our right to act with respect to subsequent or similar breaches. We do not guarantee we will take action against all breaches of this Agreement.</p>
                <p>Unme at its own discretion reserves the right to transfer or assign this Agreement or any right or obligation under this Agreement at any time, including the right to engage third party manufacturing services to perform any Services. If you are a User in the European Economic Area and such transfer or assignment may reduce your guarantees under this Agreement, then Unmee will ask for your permission beforehand.</p>
                <p>If you have any questions about this Agreement, please email us at unmeimpact@gmail.com</p>



            </section>
        </Layout>
    );
};

export default Guidelines;