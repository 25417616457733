import { useContext, useState } from 'react';
import styles from './ProductModal1.module.scss';

import { Link } from 'react-router-dom'
import {
    Modal,
    Row,
    Col,
    Radio,
    message
} from 'antd';
import { GlobalStates } from '../../Contexts/GlobalStates';

const BluePrint = ({ bulePrint, setBulePrint }) => {
    return (
        <Modal
            visible={bulePrint ? true : false}
            footer={null}
            width={window.innerWidth >= 576 ? '60vw' : '95vw'}
            centered={true}
            onCancel={() => setBulePrint(0)}
	    bodyStyle={{background:'#00A9F4'}}
        >
	<div class="modal-content-wrapper">
		<img src="/buleprint.png" style={{ height: window.innerWidth >= 650 ? '100%' : '50%', width: '100%' }} className={styles.the_image} />
	</div>
        </Modal>
    );
};

export default BluePrint;