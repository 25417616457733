import Layout from '../../Components/Layout/Layout';
import styles from './BlackView.module.scss';
import { Row, Col } from 'antd';

const BlackView = () => {
    return (
        <Layout
            title={'Why does my design look different from what I intended?'}
        >
            <section className={styles.BlackView + " " + "container"}>
	    <h2>
                Why does my design look different from what I intended?
            </h2>
                        <p><span>There are some types of direct-to-garment printing designs that can come out different than expected. That's because for designs to be visible on all non-white items, we use a white underbase—a mix of white color and the color you added in your design.</span></p>
<p><span>Our Design Maker now shows your designs as close to the real outcome as possible. When you add your design, in some cases the visual may look different than you expected. But it now limits the differences between how you see the design on screen and in real life.&nbsp;</span></p>
<p><span>We made these updates to our Design Maker so you know how your design will look when printed and there won't be any unexpected surprises for you and your customers.</span></p>
<h3><strong>What is the white underbase and why do we use it?</strong></h3>
<p><span>When printing on colorful or dark garments, a white underbase is used. The underbase is a mix of your intended color and white. The underbase cancels out the dark or bright-colored garment, making the print color stand out.</span></p>
<p><span>Learn more on </span><span>when a white underbase is used</span><span>.</span></p>
<h3><strong>What kind of designs should I avoid?</strong></h3>
<p><span>These are two of the most common design types that bring out a different result than expected:</span></p>
<ul>
<li><strong>Semi-transparent designs</strong></li>
</ul>
<p><span>Avoid using drop shadow and fade effects or </span><strong>anything else that's semi-transparent</strong><span> for your prints, as it can have unexpected results. Because of the white underbase, the semi-transparent parts can look very pale, grainy, and discolored on bright-colored and dark garments.</span></p>
<ul>
<li><strong>Black garments with a black design</strong></li>
</ul>
<p><span>Avoid black designs on black garments, as it won't have a black, glossy effect as expected. Because of the white underbase, the design will be more grayish or faded.</span></p>
<p>&nbsp;</p>
<p><img src="/lifelike-mode.png" className={styles.banner}/></p>
<h3><strong>What happens to my existing mockups that looked differently?</strong></h3>
<p><span>Once you make any changes in your existing designs, they'll automatically show the closest version to the real print outcome. As this is an updated version of our Design Maker, from now on all designs will be resembled closer to reality.</span></p>
<p><span>When designing your products you'll know ahead of time if something won't look as good as expected when printed. Plus, your mockup images will better match your customer expectations too.</span></p>
            </section>
        </Layout>
    );
};

export default BlackView;