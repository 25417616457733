import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { Web3Provider } from "@ethersproject/providers";
import { Web3ReactProvider } from "@web3-react/core";

import { providers as Web3Providers, ether } from "ethers";

//importing all components
import Landing from "./Views/Landing/Landing";
import Mockup from "./Views/Mockup/Mockup";
import Checkout from "./Views/Checkout/Checkout";
import Success from "./Views/Success/Success";
import EarnCrypto from "./Views/Earn/EarnCrypto";
import EarnSuccess from "./Views/Success/EarnSuccess";
import Admin from "./Views/Admin/Admin";
import How from "./Views/How/How";
import LearnMore from "./Views/LearnMore/LearnMore";
import Category from "./Views/Category/Category";
import Cart from "./Views/Cart/Cart";
import Guidelines from "./Views/Guidelines/Guidelines";
import Creatingemb from "./Views/Creatingemb/Creatingemb";
import Embsample from "./Views/Embsample/Embsample";
import BlackView from "./Views/BlackView/BlackView";
import { ToastContainer } from "react-toastify";
import Profile from "./Views/Profile/Profile";
import "react-toastify/dist/ReactToastify.css";

const themes = {
  light: "/custom-antd.css",
  dark: "/custom-antd-dark1.css",
};

// function getLibrary(provider) {
//   const library = new Web3Provider(provider);
//   library.pollingInterval = 12000;
//   return library;
// }

function getLibrary(provider) {
  const library = new Web3Providers.Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

function App() {
  return (
    <>
      <Web3ReactProvider getLibrary={getLibrary}>
        <ThemeSwitcherProvider defaultTheme="light" themeMap={themes}>
          <Router>
            {/* <Profile /> */}
            <ToastContainer position="top-right" />
            <Route path="/" component={Landing} exact />
            <Route path="/mockup/:id" component={Mockup} exact />
            <Route path="/checkout/:shipping" component={Checkout} exact />
            <Route path="/success" component={Success} exact />
            <Route path="/earn" component={EarnCrypto} exact />
            <Route path="/earn-success" component={EarnSuccess} exact />
            <Route path="/admin" component={Admin} exact />
            <Route path="/how-it-works" component={How} exact />
            <Route path="/learn-more" component={LearnMore} exact />
            <Route path="/category/:category" component={Category} exact />
            <Route path="/cart" component={Cart} exact />
            <Route path="/guidelines" component={Guidelines} exact />
            <Route
              path="/creating-embroidery-file"
              component={Creatingemb}
              exact
            />
            <Route path="/embroidery-sample" component={Embsample} exact />
            <Route path="/blackview" component={BlackView} exact />
          </Router>
        </ThemeSwitcherProvider>
      </Web3ReactProvider>
    </>
  );
}

export default App;
