import styles from './SignUp.module.scss';
import {  message } from 'antd';
import * as React from 'react';
import axios from '../../config/axios';
import {
    Modal
} from 'antd';
import * as antd from 'antd';

const SignIn = ({setUpOpen , setSignStatues}) => {

  

  const onFinish = values => {
    var today = new Date(),
    cur_date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + ' ' + today.getHours()+ ':' + today.getMinutes();
    var datas = {
	"email": values.username,
	"password" : values.password,
	"username" : values.first_name + values.last_name,
	"last_name":  values.last_name,
	"first_name" : values.first_name,
	"is_active" : true,
	"is_staff": false,
	"is_superuser" : false,
	"last_login" : cur_date,
	"data_joined": cur_date
    }
    console.log(values.remember)
    if(values.remember === true){
	    if(values.username.includes("@")){
	    	axios.get(`/signin/${values.username}`).then(response => {
			if(response.data.length === 0){
				axios.post('/signup', datas ).then(response1 => {
					message.success('Sign Up successfully!');
					setUpOpen(null);
				});
			}else{
				 message.success("Email duplicate");
			}
		});
		   
		   
	    }else{
		 message.success('Incorrect Email!');
	    }
   }
    
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  return (
 <Modal
 	    visible={true}
            footer={null}
            centered={true}
            onCancel={() => {setUpOpen(null);}}
  >
      <div className={styles.login_box}>
        
        <antd.Form
          name="login_form"
	  className={styles.login_form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <p className={styles.form_title}>Welcome back</p>
          <p>Sign Up to the Dashboard</p>
	  <p1>First Name</p1>
          <antd.Form.Item
            name="first_name"
            rules={[{ required: true, message: 'Please input your First Name!' }]}
          >
            <antd.Input
              placeholder="first_name"
            />
          </antd.Form.Item>
	  <p1>Last Name</p1>
	  <antd.Form.Item
            name="last_name"
            rules={[{ required: true, message: 'Please input your Last Name!' }]}
          >
            <antd.Input
              placeholder="last_name"
            />
          </antd.Form.Item>
	  <p1>Email</p1>
	  <antd.Form.Item
            name="username"
            rules={[{ required: true, message: 'Please input your Email!' }]}
          >
            <antd.Input
              placeholder="Your Email"
            />
          </antd.Form.Item>
	  <p1>Password</p1>
          <antd.Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <antd.Input.Password 
              placeholder="Password"
            />
          </antd.Form.Item>

          <antd.Form.Item name="remember" valuePropName="checked">
            <antd.Checkbox>I agree</antd.Checkbox>
          </antd.Form.Item>

          <antd.Form.Item>
            <antd.Button type="primary" htmlType="submit" className={styles.login_form_button}>
              REGISTER
            </antd.Button>
          </antd.Form.Item>
        </antd.Form>
      </div>
    </Modal>
  );
}
export default SignIn;